import { useMyFetchTyped, type ApiResult } from '~/composables/useMyFetch';
import type { PointReport, ReceiptReport, ReservationReport, UserReport } from '~/models/report';
import { useSnackbarStore } from '~/store/snackbar';
import type { OrganizationSettingRequest } from '~/models/organization';
import type {
  Campaign,
  CampaignEventTypeResponse,
  CampaignListResponse,
  CampaignResponse,
  EmailCampaignsReportResponse,
  PushCampaignsReportResponse,
} from '~/models/campaign';
import type { EmptyResponse } from '~/models/base';
import type { Coupon, CouponListResponse, CouponResponse } from '~/models/coupon';
import type { EmailPreviewOpts, EmailPreviewResponse } from '~/models/email-preview';
import { ca } from 'vuetify/lib/locale/index.mjs';

export const $api = {
  // images
  async uploadImage(image: string | ArrayBuffer | null, organizationId: number): Promise<string> {
    const {
      data: { value: data },
      error,
    }: any = await useMyFetch('/api/v1/admin/images', {
      method: 'post',
      body: {
        organization_id: organizationId,
        image: image,
      },
    });
    if (error.value) {
      const { showMessage } = useSnackbarStore();
      showMessage({
        content: '画像のアップロードが失敗しました',
        color: 'error',
      });
      return '';
    }

    return data.url;
  },

  // menu items
  async fetchMenuItems(): Promise<MenuItem[]> {
    const {
      data: { value: data },
      error,
    }: any = await useMyFetch('/api/v1/admin/menu-items', {
      method: 'get',
    });
    if (error.value) {
      const { showMessage } = useSnackbarStore();
      showMessage({ content: 'メニューの取得が失敗しました', color: 'error' });
      return [];
    }

    return data.menu_items as MenuItem[];
  },

  async fetchCampaigns(page: number, perPage: number): Promise<ApiResult<CampaignListResponse>> {
    return useMyFetchTyped<CampaignListResponse>('/api/v1/admin/campaigns', {
      method: 'GET',
      params: { page, per_page: perPage },
    });
  },

  async fetchCampaignDetail(id: number): Promise<ApiResult<CampaignResponse>> {
    return useMyFetchTyped<CampaignResponse>(`/api/v1/admin/campaigns/${id}`, {
      method: 'GET',
    });
  },

  async createCampaign(attributes: Partial<Campaign>): Promise<ApiResult<CampaignResponse>> {
    return useMyFetchTyped<CampaignResponse>(`/api/v1/admin/campaigns`, {
      method: 'POST',
      body: attributes,
    });
  },

  async updateCampaign(id: number, updated: Partial<Campaign>): Promise<ApiResult<CampaignResponse>> {
    return useMyFetchTyped<CampaignResponse>(`/api/v1/admin/campaigns/${id}`, {
      method: 'PUT',
      body: updated,
    });
  },

  async deleteCampaign(id: number): Promise<ApiResult<EmptyResponse>> {
    return useMyFetchTyped<EmptyResponse>(`/api/v1/admin/campaigns/${id}`, {
      method: 'DELETE',
    });
  },

  async fetchUserSegments(page: number, perPage: number): Promise<ApiResult<UserSegmentListResponse>> {
    return useMyFetchTyped<UserSegmentListResponse>('/api/v1/admin/user-segments', {
      method: 'GET',
      params: { page, per_page: perPage },
    });
  },

  async fetchUserSegmentDetail(id: number): Promise<ApiResult<UserSegmentResponse>> {
    return useMyFetchTyped<UserSegmentResponse>(`/api/v1/admin/user-segments/${id}`, {
      method: 'GET',
    });
  },

  async createUserSegment(attributes: Partial<UserSegment>): Promise<ApiResult<UserSegmentResponse>> {
    return useMyFetchTyped<UserSegmentResponse>(`/api/v1/admin/user-segments`, {
      method: 'POST',
      body: attributes,
    });
  },

  async updateUserSegment(id: number, updated: Partial<UserSegment>): Promise<ApiResult<UserSegmentResponse>> {
    return useMyFetchTyped<UserSegmentResponse>(`/api/v1/admin/user-segments/${id}`, {
      method: 'PUT',
      body: updated,
    });
  },

  async deleteUserSegment(id: number): Promise<ApiResult<EmptyResponse>> {
    return useMyFetchTyped<EmptyResponse>(`/api/v1/admin/user-segments/${id}`, {
      method: 'DELETE',
    });
  },

  async fetchUserSegmentAttributes(): Promise<ApiResult<UserSegmentAttributeListResponse>> {
    return useMyFetchTyped<UserSegmentAttributeListResponse>('/api/v1/admin/user-segments/attributes', {
      method: 'GET',
    });
  },

  async fetchUserSegmentUserCount(
    criteria: UserSegmentCriteriaItem[],
  ): Promise<ApiResult<UserSegmentUserCountResponse>> {
    return useMyFetchTyped<UserSegmentUserCountResponse>(`/api/v1/admin/user-segments/user-count`, {
      method: 'POST',
      body: {
        criteria,
      },
    });
  },

  async fetchNews(page: number, perPage: number): Promise<ApiResult<NewsListResponse>> {
    return useMyFetchTyped<NewsListResponse>('/api/v1/admin/news', {
      method: 'GET',
      params: { page, per_page: perPage },
    });
  },

  async fetchNewsDetail(id: number): Promise<ApiResult<NewsResponse>> {
    return useMyFetchTyped<NewsResponse>(`/api/v1/admin/news/${id}`, {
      method: 'GET',
    });
  },

  async createNews(attributes: Partial<News>): Promise<ApiResult<NewsResponse>> {
    return useMyFetchTyped<NewsResponse>(`/api/v1/admin/news`, {
      method: 'POST',
      body: attributes,
    });
  },

  async updateNews(id: number, updated: Partial<News>): Promise<ApiResult<NewsResponse>> {
    return useMyFetchTyped<NewsResponse>(`/api/v1/admin/news/${id}`, {
      method: 'PUT',
      body: updated,
    });
  },

  async deleteNews(id: number): Promise<ApiResult<EmptyResponse>> {
    return useMyFetchTyped<EmptyResponse>(`/api/v1/admin/news/${id}`, {
      method: 'DELETE',
    });
  },

  async fetchCoupons(opts: { page?: number; perPage?: number; code?: string }): Promise<ApiResult<CouponListResponse>> {
    return useMyFetchTyped<CouponListResponse>('/api/v1/admin/coupons', {
      method: 'GET',
      params: { page: opts.page, per_page: opts.perPage, code: opts.code },
    });
  },

  async fetchCouponDetail(id: number): Promise<ApiResult<CouponResponse>> {
    return useMyFetchTyped<CouponResponse>(`/api/v1/admin/coupons/${id}`, {
      method: 'GET',
    });
  },

  async createCoupon(attributes: Partial<Coupon>): Promise<ApiResult<CouponResponse>> {
    return useMyFetchTyped<CouponResponse>(`/api/v1/admin/coupons`, {
      method: 'POST',
      body: attributes,
    });
  },

  async updateCoupon(id: number, updated: Partial<Coupon>): Promise<ApiResult<CouponResponse>> {
    return useMyFetchTyped<CouponResponse>(`/api/v1/admin/coupons/${id}`, {
      method: 'PUT',
      body: updated,
    });
  },

  async deleteCoupon(id: number): Promise<ApiResult<EmptyResponse>> {
    return useMyFetchTyped<EmptyResponse>(`/api/v1/admin/coupons/${id}`, {
      method: 'DELETE',
    });
  },

  async fetchCampaignEventTypes(): Promise<ApiResult<CampaignEventTypeResponse>> {
    return useMyFetchTyped<CampaignEventTypeResponse>(`/api/v1/admin/campaign-event-types`, {
      method: 'GET',
    });
  },

  async adminFetchUsers(page: number, perPage: number, searchText?: string): Promise<ApiResult<AdminUsersResponse>> {
    return useMyFetchTyped<AdminUsersResponse>('/api/v1/admin/users', {
      method: 'GET',
      params: { page, per_page: perPage, search: searchText },
    });
  },

  async adminFetchUserDetail(userId: number): Promise<ApiResult<AdminUser>> {
    return useMyFetchTyped<AdminUser>(`/api/v1/admin/users/${userId}`, {
      method: 'GET',
    });
  },

  async fetchUserReceiptOrders(userId: number): Promise<ApiResult<UserReceiptOrderResponse>> {
    return useMyFetchTyped<UserReceiptOrderResponse>(`/api/v1/admin/users/${userId}/receipt-orders`, {
      method: 'GET',
    });
  },

  async adminFetchUserReservations(
    userId: number,
    page: number,
    perPage: number,
    allShop: boolean = false,
  ): Promise<ApiResult<AdminUserReservationsResponse>> {
    return useMyFetchTyped<AdminUserReservationsResponse>(`/api/v1/admin/users/${userId}/reservations`, {
      method: 'GET',
      params: {
        page: page,
        per_page: perPage,
        all_shops: allShop,
      },
    });
  },

  async adminFetchUserPointHistories(
    userId: number,
    page: number,
    perPage: number,
  ): Promise<ApiResult<AdminUserPointHistoriesResponse>> {
    return useMyFetchTyped<AdminUserPointHistoriesResponse>(`/api/v1/admin/users/${userId}/point-histories`, {
      method: 'GET',
      params: { page, per_page: perPage },
    });
  },

  async fetchMenuItem(id: number): Promise<MenuItem> {
    const {
      data: { value: data },
      error,
    }: any = await useMyFetch(`/api/v1/admin/menu-items/${id}`, {
      method: 'get',
    });
    if (error.value) {
      const { showMessage } = useSnackbarStore();
      showMessage({ content: 'メニューの取得が失敗しました', color: 'error' });
      return {} as MenuItem;
    }

    return data.menu_item as MenuItem;
  },

  async updateMenuItem(menuItem: MenuItem): Promise<boolean> {
    const { error }: any = await useMyFetch(`/api/v1/admin/menu-items/${menuItem.id}`, {
      method: 'put',
      body: menuItem,
    });
    const { showMessage } = useSnackbarStore();
    if (error.value) {
      showMessage({ content: 'メニューの更新が失敗しました', color: 'error' });
      return false;
    }

    showMessage({ content: 'メニューを更新しました', color: 'success' });
    return true;
  },

  async createMenuItem(menuItem: MenuItem): Promise<boolean> {
    const { error }: any = await useMyFetch('/api/v1/admin/menu-items', {
      method: 'post',
      body: menuItem,
    });
    const { showMessage } = useSnackbarStore();
    if (error.value) {
      showMessage({ content: 'メニューの登録が失敗しました', color: 'error' });
      return false;
    }

    showMessage({ content: 'メニューを登録しました', color: 'success' });
    return true;
  },

  async deleteMenuItem(id: number): Promise<boolean> {
    const { error }: any = await useMyFetch(`/api/v1/admin/menu-items/${id}`, {
      method: 'delete',
    });
    const { showMessage } = useSnackbarStore();
    if (error.value) {
      showMessage({ content: 'メニューの削除が失敗しました', color: 'error' });
      return false;
    }

    showMessage({ content: 'メニューを削除しました', color: 'success' });
    return true;
  },

  // reservations
  async fetchReservations(
    date: string,
    status: string,
    page: number,
    customerType: string,
  ): Promise<ReservationListResponse> {
    const {
      data: { value: data },
      error,
    }: any = await useMyFetch('/api/v1/admin/reservations', {
      method: 'get',
      params: {
        date,
        status,
        page,
        customer_type: customerType,
      },
    });
    if (error.value) {
      const { showMessage } = useSnackbarStore();
      showMessage({ content: '予約の取得が失敗しました', color: 'error' });
      return {
        pagination: {},
      } as ReservationListResponse;
    }

    return data as ReservationListResponse;
  },

  async fetchReservation(id: Number): Promise<ReservationResponse> {
    const {
      data: { value: data },
      error,
    }: any = await useMyFetch(`/api/v1/admin/reservations/${id}`, {
      method: 'get',
    });
    if (error.value) {
      const { showMessage } = useSnackbarStore();
      showMessage({ content: '予約の取得が失敗しました', color: 'error' });
      return {} as ReservationResponse;
    }

    return data as ReservationResponse;
  },

  async completeReservation(id: number, points: number, totalAmount: number): Promise<boolean> {
    const { error }: any = await useMyFetch(`/api/v1/admin/reservations/${id}/complete`, {
      method: 'post',
      body: {
        points: points,
        total_amount: totalAmount,
      },
    });
    const { showMessage } = useSnackbarStore();
    if (error.value) {
      showMessage({ content: '予約の確定が失敗しました', color: 'error' });
      return false;
    }

    showMessage({ content: '予約の確定を完了しました', color: 'success' });
    return true;
  },

  // organizations
  async fetchOrganizations(): Promise<Organization[]> {
    const {
      data: { value: data },
      error,
    }: any = await useMyFetch('/api/v1/admin/organizations', {
      method: 'get',
    });
    if (error.value) {
      const { showMessage } = useSnackbarStore();
      showMessage({ content: '組織の取得が失敗しました', color: 'error' });
      return [];
    }

    return data.organizations as Organization[];
  },

  async fetchOrganization(id: number): Promise<Organization> {
    const {
      data: { value: data },
      error,
    }: any = await useMyFetch(`/api/v1/admin/organizations/${id}`, {
      method: 'get',
    });
    if (error.value) {
      const { showMessage } = useSnackbarStore();
      showMessage({ content: '組織の取得が失敗しました', color: 'error' });
      return {} as Organization;
    }

    return data.organization as Organization;
  },

  async updateOrganization(organization: Organization): Promise<boolean> {
    const { error }: any = await useMyFetch(`/api/v1/admin/organizations/${organization.id}`, {
      method: 'put',
      body: organization,
    });
    const { showMessage } = useSnackbarStore();
    if (error.value) {
      showMessage({ content: '組織の更新が失敗しました', color: 'error' });
      return false;
    }

    showMessage({ content: '組織を更新しました', color: 'success' });
    return true;
  },

  // operators
  async fetchOperators(page: number): Promise<OperatorListResponse> {
    const {
      data: { value: data },
      error,
    }: any = await useMyFetch('/api/v1/admin/operators', {
      method: 'get',
      params: {
        page: page,
      },
    });
    if (error.value) {
      const { showMessage } = useSnackbarStore();
      showMessage({
        content: 'アカウントの取得が失敗しました',
        color: 'error',
      });
      return {} as OperatorListResponse;
    }

    return data as OperatorListResponse;
  },

  async fetchOperator(id: number): Promise<Operator> {
    const {
      data: { value: data },
      error,
    }: any = await useMyFetch(`/api/v1/admin/operators/${id}`, {
      method: 'get',
    });
    if (error.value) {
      const { showMessage } = useSnackbarStore();
      showMessage({
        content: 'アカウントの取得が失敗しました',
        color: 'error',
      });
      return {} as Operator;
    }

    return data.operator as Operator;
  },

  async updateOperator(operator: Operator): Promise<boolean> {
    const { error }: any = await useMyFetch(`/api/v1/admin/operators/${operator.id}`, {
      method: 'put',
      body: operator,
    });
    const { showMessage } = useSnackbarStore();
    if (error.value) {
      const data = error.value.data;
      let message = 'アカウントの更新が失敗しました';
      if (data.exception == 'ValidationException') {
        message = data.message;
      }
      showMessage({ content: message, color: 'error' });
      return false;
    }

    showMessage({ content: 'アカウントを更新しました', color: 'success' });
    return true;
  },

  async createOperator(operator: Operator): Promise<boolean> {
    const { error }: any = await useMyFetch('/api/v1/admin/operators', {
      method: 'post',
      body: operator,
    });
    const { showMessage } = useSnackbarStore();
    if (error.value) {
      const data = error.value.data;
      let message = 'アカウントの登録が失敗しました';
      if (data.exception == 'ValidationException') {
        message = data.message;
      }
      showMessage({ content: message, color: 'error' });
      return false;
    }

    showMessage({ content: 'アカウントを登録しました', color: 'success' });
    return true;
  },

  async deleteOperator(id: number): Promise<boolean> {
    const { error }: any = await useMyFetch(`/api/v1/admin/operators/${id}`, {
      method: 'delete',
    });
    const { showMessage } = useSnackbarStore();
    if (error.value) {
      showMessage({
        content: 'アカウントの削除が失敗しました',
        color: 'error',
      });
      return false;
    }

    showMessage({ content: 'アカウントを削除しました', color: 'success' });
    return true;
  },

  async switchShop(shopId: number): Promise<boolean> {
    const { error }: any = await useMyFetch(`/api/v1/admin/profile/switch-shop`, {
      method: 'post',
      body: {
        shop_id: shopId,
      },
    });
    const { showMessage } = useSnackbarStore();
    if (error.value) {
      showMessage({ content: '店舗の切り替えが失敗しました', color: 'error' });
      return false;
    }

    return true;
  },

  async switchOrganization(orgId: number): Promise<boolean> {
    const { error }: any = await useMyFetch(`/api/v1/admin/profile/switch-organization`, {
      method: 'post',
      body: {
        organization_id: orgId,
      },
    });
    const { showMessage } = useSnackbarStore();
    if (error.value) {
      showMessage({ content: '組織の切り替えが失敗しました', color: 'error' });
      return false;
    }

    return true;
  },

  // admin - shops
  async fetchAdminShops(): Promise<AdminShop[]> {
    const {
      data: { value: data },
      error,
    }: any = await useMyFetch('/api/v1/admin/shops', {
      method: 'get',
    });
    if (error.value) {
      console.log(error.value);
      const { showMessage } = useSnackbarStore();
      showMessage({ content: '店舗の取得が失敗しました', color: 'error' });
      return [];
    }

    return data.items as AdminShop[];
  },

  async fetchAdminShop(id: number): Promise<AdminShop> {
    const {
      data: { value: data },
      error,
    }: any = await useMyFetch(`/api/v1/admin/shops/${id}`, {
      method: 'get',
    });
    if (error.value) {
      const { showMessage } = useSnackbarStore();
      showMessage({ content: '店舗の取得が失敗しました', color: 'error' });
      return {} as AdminShop;
    }

    return data as AdminShop;
  },

  async createAdminShop(shop: AdminShop): Promise<boolean> {
    const { error }: any = await useMyFetch('/api/v1/admin/shops', {
      method: 'post',
      body: shop,
    });
    const { showMessage } = useSnackbarStore();
    if (error.value) {
      showMessage({ content: '店舗の登録が失敗しました', color: 'error' });
      return false;
    }

    showMessage({ content: '店舗を登録しました', color: 'success' });
    return true;
  },

  async updateAdminShop(shop: AdminShop): Promise<boolean> {
    const { error }: any = await useMyFetch(`/api/v1/admin/shops/${shop.id}`, {
      method: 'put',
      body: shop,
    });
    const { showMessage } = useSnackbarStore();
    if (error.value) {
      showMessage({ content: '店舗の更新が失敗しました', color: 'error' });
      return false;
    }

    showMessage({ content: '店舗を更新しました', color: 'success' });
    return true;
  },

  async updateAdminShopSetting(setting: AdminShopSettingRequest): Promise<boolean> {
    const { error }: any = await useMyFetch(`/api/v1/admin/shops/${setting.shopId}/settings`, {
      method: 'put',
      body: {
        logo_url: setting.logoUrl,
        image_urls: setting.imageUrls,
      },
    });
    const { showMessage } = useSnackbarStore();
    if (error.value) {
      showMessage({
        content: '店舗の設定の更新が失敗しました',
        color: 'error',
      });
      return false;
    }

    showMessage({ content: '店舗の設定を更新しました', color: 'success' });
    return true;
  },

  async updateOrganizationSetting(setting: OrganizationSettingRequest): Promise<boolean> {
    const { error }: any = await useMyFetch(`/api/v1/admin/organizations/${setting.organizationId}/settings`, {
      method: 'put',
      body: {
        tablecheck_api_key: setting.tablecheck_api_key,
      },
    });
    const { showMessage } = useSnackbarStore();
    if (error.value) {
      showMessage({
        content: '組織の設定の更新が失敗しました',
        color: 'error',
      });
      return false;
    }

    showMessage({ content: '組織の設定を更新しました', color: 'success' });
    return true;
  },

  async deleteAdminShop(id: number): Promise<boolean> {
    const { error }: any = await useMyFetch(`/api/v1/admin/shops/${id}`, {
      method: 'delete',
    });
    const { showMessage } = useSnackbarStore();
    if (error.value) {
      showMessage({ content: '店舗の削除が失敗しました', color: 'error' });
      return false;
    }

    showMessage({ content: '店舗を削除しました', color: 'success' });
    return true;
  },

  // Reports
  async fetchReservationReport({
    startDate,
    endDate,
    span,
    shopId,
  }: {
    startDate: string;
    endDate: string;
    span: string;
    shopId?: number | null;
  }): Promise<ReservationReport> {
    let params: any = {
      start_date: startDate,
      end_date: endDate,
      span: span,
    };
    if (shopId) {
      params['shop_id'] = shopId;
    }
    const {
      data: { value: data },
      error,
    }: any = await useMyFetch('/api/v1/admin/reports/reservations', {
      method: 'get',
      params: params,
    });

    if (error.value) {
      const { showMessage } = useSnackbarStore();
      showMessage({ content: '予約レポートの取得が失敗しました', color: 'error' });
      return {} as ReservationReport;
    }

    return data as ReservationReport;
  },

  async fetchPointReport({ startDate, endDate }: { startDate: string; endDate: string }): Promise<PointReport> {
    const {
      data: { value: data },
      error,
    }: any = await useMyFetch('/api/v1/admin/reports/points', {
      method: 'get',
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    });

    if (error.value) {
      const { showMessage } = useSnackbarStore();
      showMessage({ content: 'ポイントレポートの取得が失敗しました', color: 'error' });
      return {} as PointReport;
    }

    return data as PointReport;
  },

  async fetchUserReport({ startDate, endDate }: { startDate: string; endDate: string }): Promise<UserReport> {
    const {
      data: { value: data },
      error,
    }: any = await useMyFetch('/api/v1/admin/reports/users', {
      method: 'get',
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    });

    if (error.value) {
      const { showMessage } = useSnackbarStore();
      showMessage({ content: '会員レポートの取得が失敗しました', color: 'error' });
      return {} as UserReport;
    }

    return data as UserReport;
  },

  async fetchReceiptReport({
    startDate,
    endDate,
    shopId,
  }: {
    startDate: string;
    endDate: string;
    shopId?: number | null;
  }): Promise<ReceiptReport> {
    let params: any = {
      start_date: startDate,
      end_date: endDate,
    };
    if (shopId) {
      params['shop_id'] = shopId;
    }
    const {
      data: { value: data },
      error,
    }: any = await useMyFetch('/api/v1/admin/reports/receipts', {
      method: 'get',
      params: params,
    });

    if (error.value) {
      const { showMessage } = useSnackbarStore();
      showMessage({ content: '会計レポートの取得が失敗しました', color: 'error' });
      return {} as ReceiptReport;
    }

    return data as ReceiptReport;
  },

  async fetchEmailCampaignsReport({
    fromDate,
    toDate,
    campaignId,
  }: {
    fromDate: string;
    toDate: string;
    campaignId?: number;
  }): Promise<ApiResult<EmailCampaignsReportResponse>> {
    return useMyFetchTyped('/api/v1/admin/reports/campaigns/email', {
      method: 'get',
      params: {
        from_date: fromDate,
        to_date: toDate,
        campaign_id: campaignId,
      },
    });
  },

  async fetchPushCampaignsReport({
    fromDate,
    toDate,
    campaignId,
  }: {
    fromDate: string;
    toDate: string;
    campaignId?: number;
  }): Promise<ApiResult<PushCampaignsReportResponse>> {
    return useMyFetchTyped<PushCampaignsReportResponse>('/api/v1/admin/reports/campaigns/push-notification', {
      method: 'get',
      params: {
        from_date: fromDate,
        to_date: toDate,
        campaign_id: campaignId,
      },
    });
  },

  async fetchRFMReservationSegments(): Promise<ApiResult<RFMReservationSegmentResponse>> {
    return useMyFetchTyped<RFMReservationSegmentResponse>(`/api/v1/admin/rfm/reservations`, {
      method: 'GET',
    });
  },

  // Email Preview
  async fetchEmailPreview({ type, body }: EmailPreviewOpts): Promise<ApiResult<EmailPreviewResponse>> {
    return useMyFetchTyped<EmailPreviewResponse>('/api/v1/admin/email-preview', {
      method: 'GET',
      params: { type, body },
    });
  },

  async fetchStatisticPageView({
    startDate,
    endDate,
    shopId,
  }: {
    startDate: string;
    endDate: string;
    shopId?: number | null;
  }): Promise<ApiResult<StatisticPageViewResponse>> {
    return useMyFetchTyped<StatisticPageViewResponse>('/api/v1/admin/statistics/page-view', {
      method: 'GET',
      params: {
        start_date: startDate,
        end_date: endDate,
        shop_id: shopId,
      },
    });
  },

  async fetchStatisticReservationAction({
    startDate,
    endDate,
    shopId,
  }: {
    startDate: string;
    endDate: string;
    shopId?: number | null;
  }): Promise<ApiResult<StatisticReservationActionResponse>> {
    return useMyFetchTyped<StatisticReservationActionResponse>('/api/v1/admin/statistics/reservation-action', {
      method: 'GET',
      params: {
        start_date: startDate,
        end_date: endDate,
        shop_id: shopId,
      },
    });
  },

  async adminFetchGenres(params: { page?: number, perPage?: number, searchText?: string, ids?: number[] }): Promise<ApiResult<AdminGenresResponse>> {
    return useMyFetchTyped<AdminGenresResponse>('/api/v1/admin/genres', {
      method: 'GET',
      params: {
        page: params.page,
        per_page: params.perPage,
        search: params.searchText,
        'ids[]': params.ids,
      },
    });
  },

  async createGenre(genre: Genre): Promise<ApiResult<Genre>> {
    return useMyFetchTyped<Genre>('/api/v1/admin/genres', {
      method: 'POST',
      body: genre,
    });
  },

  async updateGenre(id: number, genre: Genre): Promise<ApiResult<Genre>> {
    return useMyFetchTyped<Genre>(`/api/v1/admin/genres/${id}`, {
      method: 'PUT',
      body: genre,
    });
  },

  async deleteGenre(id: number): Promise<ApiResult<EmptyResponse>> {
    return useMyFetchTyped<Genre>(`/api/v1/admin/genres/${id}`, {
      method: 'DELETE',
    });
  },

  async fetchGenreDetail(id: number): Promise<ApiResult<Genre>> {
    return useMyFetchTyped<Genre>(`/api/v1/admin/genres/${id}`, {
      method: 'GET',
    });
  },
};
